<template>
  <!-- Tabbar -->
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        id="driveOff-tab"
        data-bs-toggle="tab"
        type="button"
        role="tab"
        aria-controls="home"
        aria-selected="false"
        @click="$router.push('/claims/new/driveoff')"
      >
        {{ $t("claims.processing.driveoff") }}
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link active"
        id="nmop-tab"
        data-bs-toggle="tab"
        type="button"
        role="tab"
        aria-controls="profile"
        aria-selected="true"
      >
        {{ $t("claims.processing.nmop-title") }}
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        :class="{ disabled: !showCnaf }"
        id="cnaf-tab"
        data-bs-toggle="tab"
        type="button"
        role="tab"
        aria-controls="home"
        aria-selected="false"
        @click="$router.push('/claims/new/cnaf')"
      >
      {{ $t("claims.processing.cnaf-title") }}<span
          v-if="!showCnaf"
          class="badge badge-warning badge-style-light card-abandoned"
          >COMING SOON</span
        >
      </button>
    </li>
    <!-- <li class="nav-item" role="presentation">
        <button class="nav-link disabled" id="abandoned-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="contact" aria-selected="false">{{ $t('claims.new.abandoned.title')}}</button>
    </li> -->
  </ul>

  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <Form
            ref="claimForm"
            @submit="(values, { resetForm }) => createClaim(values, resetForm)"
            :validation-schema="schema"
            id="createnmop"
          >
            <div class="row mb-3">
              <label for="siteFilter" class="col-sm-2 col-form-label"
                >{{ $t("claims.new.site") }}
                <span class="text-danger">*</span></label
              >
              <div class="col-sm-10">
                <Multiselect
                  :class="{ 'multi-dark': $store.state.auth.status.dark }"
                  mode="tags"
                  placeholder="Select a site"
                  :close-on-select="false"
                  :searchable="true"
                  :create-option="true"
                  :multiple="false"
                  :options="sites"
                  label="name"
                  track-by="id"
                  name="site"
                  v-model="claim.site"
                />
              </div>
            </div>
            <hr />

            <div v-show="lockForm">
              <div
                class="alert alert-custom alert-indicator-left indicator-warning"
                role="alert"
              >
                <div class="alert-content">
                  <span class="alert-title">Claim submissions disabled</span>
                  <span class="alert-text"
                    >The site you have selected is Discontinued. Due to this, we
                    are not able to accept claim submissions at this time. If
                    you believe this is a mistake, please contact our support
                    team at
                    <a href="mailto:support@varstechnology.co.uk"
                      >support@varstechnology.co.uk</a
                    ></span
                  >
                </div>
              </div>
            </div>

            <div v-show="!claim.site">
              <div
                class="alert alert-custom alert-indicator-left indicator-warning"
                role="alert"
              >
                <div class="alert-content">
                  <span class="alert-title">Please select which site this claim is for</span>
                  <span class="alert-text"
                    >In order to continue, please select which site this claim is for.</span
                  >
                </div>
              </div>
            </div>
            
            <div v-show="!lockForm && claim.site">
              <div class="row mb-3">
                <label for="vehicleRegistration" class="col-sm-2 col-form-label"
                  >{{ $t("claims.new.vehicleRegistration") }}
                  <span class="text-danger">*</span></label
                >
                <div class="col-sm-10">
                  <Field
                    type="text"
                    name="registration"
                    @keyup="getMMC"
                    @blur="vehicleCheck"
                    class="form-control"
                    id="vehicleRegistration"
                    aria-describedby="registrationHelpBlock"
                    placeholder="Enter vehicle registration number"
                  />
                  <div id="registrationHelpBlock" class="form-text">
                    {{ $t("claims.new.registration_feedback") }}
                  </div>
                  <ErrorMessage name="registration" class="error-feedback" />
                </div>
              </div>
              <div class="row mb-3">
                <label for="vehicleMake" class="col-sm-2 col-form-label"
                  >{{ $t("claims.new.vehicle_make") }}
                  <span class="text-danger">*</span></label
                >
                <div class="col-sm-10">
                  <Field
                    type="text"
                    name="make"
                    class="form-control"
                    id="vehicleMake"
                    placeholder="Vehicle Make"
                  />
                  <ErrorMessage name="make" class="error-feedback" />
                </div>
              </div>
              <div class="row mb-3">
                <label for="vehicleModel" class="col-sm-2 col-form-label"
                  >{{ $t("claims.new.vehicle_model") }}
                  <span class="text-danger">*</span></label
                >
                <div class="col-sm-10">
                  <Field
                    type="text"
                    name="model"
                    class="form-control"
                    id="vehicleModel"
                    placeholder="Vehicle Model"
                  />
                  <ErrorMessage name="model" class="error-feedback" />
                </div>
              </div>
              <div class="row mb-3">
                <label for="vehicleColour" class="col-sm-2 col-form-label"
                  >{{ $t("claims.new.vehicle_colour") }}
                  <span class="text-danger">*</span></label
                >
                <div class="col-sm-10">
                  <Field
                    type="text"
                    name="colour"
                    class="form-control"
                    id="vehicleColour"
                    placeholder="Vehicle Colour"
                  />
                  <ErrorMessage name="colour" class="error-feedback" />
                </div>
              </div>
              <hr />
              <div class="row mb-3">
                <label for="date" class="col-sm-2 col-form-label"
                  >{{ $t("claims.new.date") }}
                  <span class="text-danger">*</span></label
                >
                <div class="col-sm-10">
                  <Field
                    type="date"
                    :class="{ 'is-invalid': is_future }"
                    v-model="claim.date"
                    name="date"
                    class="form-control"
                    id="date"
                  />
                  <ErrorMessage name="date" class="error-feedback" />
                  <div class="invalid-feedback">
                    <p>The incident date/time must not be in the future!</p>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <label for="time" class="col-sm-2 col-form-label"
                  >{{ $t("claims.new.time") }}
                  <span class="text-danger">*</span></label
                >
                <div class="col-sm-10">
                  <Field
                    type="time"
                    :class="{ 'is-invalid': is_future }"
                    v-model="claim.time"
                    name="time"
                    class="form-control"
                    id="time"
                  />
                  <ErrorMessage name="time" class="error-feedback" />
                  <div class="invalid-feedback">
                    <p>The incident date/time must not be in the future!</p>
                  </div>
                </div>
              </div>
              <hr />

              <div class="card fuel-input-card" id="createnmop">
                <div class="card-body">
                  <div class="grid">
                    <div class="form-label">
                      {{ $t("claims.new.type_of_fuel") }}
                      <span class="text-danger">*</span>
                    </div>
                    <div class="form-label">
                      {{ $t("claims.new.value") }}
                      <span class="text-danger">*</span>
                    </div>
                    <div class="form-label">
                      {{ $t("claims.new.litres") }}
                      <span class="text-danger">*</span>
                    </div>
                    <div class="form-label">
                      {{ $t("claims.new.pump") }}
                      <span class="text-danger">*</span>
                    </div>
                  </div>
                  <div
                    v-for="fuel in claim.fuels"
                    :key="fuel.id"
                    class="grid m-b-sm"
                  >
                    <div>
                      <select v-model="fuel.type" class="form-select" required>
                        <option value="" selected>
                          {{ $t("claims.new.select_a_type") }}
                        </option>
                        <option>{{ $t("claims.new.petrol") }}</option>
                        <option>{{ $t("claims.new.diesel") }}</option>
                        <option>{{ $t("claims.new.adblue") }}</option>
                        <option>
                          {{ $t("claims.new.liquid_petroleum_gas") }}
                        </option>
                        <option>{{ $t("claims.new.reddiesel") }}</option>
                      </select>
                      <div :id="fuel.id + 'type'" class="invalid-feedback">
                        {{ $t("claims.new.fuel_type_feedback") }}
                      </div>
                    </div>
                    <div>
                      <input
                        v-model="fuel.value"
                        placeholder="0.00"
                        class="form-control"
                        type="number"
                        step="0.01"
                        min="0.00"
                        max="10000"
                        required
                      />
                      <div :id="fuel.id + 'value'" class="invalid-feedback">
                        {{ $t("claims.new.fuel_value_feedback") }}
                      </div>
                    </div>
                    <div>
                      <input
                        v-model="fuel.litres"
                        placeholder="0.00"
                        class="form-control"
                        type="number"
                        step="0.01"
                        min="0.00"
                        max="10000"
                        required
                      />
                      <div :id="fuel.id + 'litres'" class="invalid-feedback">
                        {{ $t("claims.new.fuel_litres_feedback") }}
                      </div>
                    </div>
                    <div>
                      <input
                        v-model="fuel.pump"
                        placeholder="0"
                        class="form-control"
                        type="number"
                        min="1"
                        max="20"
                        required
                      />
                      <div :id="fuel.id + 'pump'" class="invalid-feedback">
                        {{ $t("claims.new.fuel_pump_feedback") }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-show="claim.fuels.length < 2"
                    id="second-fuel"
                    @click="addFuel"
                  >
                    <div class="new-fuel-btn">
                      <i
                        class="material-icons text-success p-h-xs"
                        style="vertical-align: middle"
                        >add_circle</i
                      >
                      <span class="text-success">
                        {{ $t("claims.new.add_new_fuel") }}
                      </span>
                    </div>
                  </div>

                  <button
                    v-if="claim.fuels.length > 1"
                    class="btn btn-danger"
                    style="float: right"
                    @click.prevent="deleteFuel(claim.fuels[1])"
                  >
                    {{ $t("claims.new.remove_second_fuel") }}
                  </button>
                </div>
              </div>
              <hr />
              <div
                class="alert alert-custom alert-indicator-left indicator-danger"
                role="alert"
                id="dangercard"
              >
                <div class="alert-content">
                  <span class="alert-title">{{
                    $t("claims.new.very_important")
                  }}</span>
                  <span class="alert-text">{{
                    $t("claims.new.name_and_address_alert")
                  }}</span>
                </div>
              </div>
              <div class="row mb-3">
                <label for="customerName" class="col-sm-2 col-form-label"
                  >{{ $t("claims.new.customer_name") }}
                  <span class="text-danger">*</span></label
                >
                <div class="col-sm-10">
                  <Field
                    type="text"
                    name="customer"
                    class="form-control"
                    id="customerName"
                    placeholder="Enter customer name"
                  />
                  <ErrorMessage name="customer" class="error-feedback" />
                </div>
              </div>
              <div class="row mb-3">
                <label for="customerEmail" class="col-sm-2 col-form-label">{{
                  $t("claims.new.customer_email")
                }}</label>
                <div class="col-sm-10">
                  <Field
                    ref="emailInput"
                    type="email"
                    @blur="checkEmailValidity"
                    name="email"
                    class="form-control"
                    id="customerEmail"
                    placeholder="Enter customer email address (optional)"
                  />
                  <ErrorMessage name="email" class="error-feedback" />
                </div>
              </div>
              <div class="row mb-3">
                <label for="customerMobile" class="col-sm-2 col-form-label">{{
                  $t("claims.new.customer_mobile")
                }}</label>
                <div class="col-sm-10">
                  <Field
                    type="tel"
                    name="mobile"
                    class="form-control"
                    id="customerMobile"
                    placeholder="+44 "
                  />
                  <ErrorMessage name="mobile" class="error-feedback" />
                </div>
              </div>
              <div class="row mb-3">
                <label for="customerPostcode" class="col-sm-2 col-form-label"
                  >{{ $t("claims.new.customer_postcode") }}
                  <span class="text-danger">*</span></label
                >
                <div class="col-sm-8">
                  <Field
                    type="tel"
                    name="postcode"
                    v-model="claim.postcode"
                    class="form-control"
                    id="customerPostcode"
                    placeholder="Enter customer's postcode"
                  />
                  <ErrorMessage name="postcode" class="error-feedback" />
                </div>
                <div class="col-sm-2">
                  <button
                    class="btn btn-primary float-end"
                    @click.prevent="getAddress"
                    id="getAddressBtn"
                  >
                    <span
                      v-show="findingAddresses"
                      class="spinner-border spinner-border-sm m-r-xxs"
                    ></span>
                    <span>{{ $t("claims.new.locate_address") }}</span>
                  </button>
                </div>
              </div>
              <div class="row mb-3">
                <label for="addressFilter" class="col-sm-2 col-form-label">{{
                  $t("claims.new.select_address")
                }}</label>
                <div class="col-sm-10">
                  <select
                    id="addressFilter"
                    class="form-select"
                    @change="selectAddress"
                  >
                    <option value="" selected>
                      - {{ $t("claims.new.select_an_address") }} -
                    </option>
                    <option
                      v-for="(address, index) in addresses"
                      :key="'add-' + index"
                      :value="address"
                    >
                      {{ address }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <label for="addressOne" class="col-sm-2 col-form-label"
                  >{{ $t("claims.new.address_one") }}
                  <span class="text-danger">*</span></label
                >
                <div class="col-sm-10">
                  <Field
                    type="text"
                    name="addressOne"
                    class="form-control"
                    id="addressOne"
                  />
                  <ErrorMessage name="addressOne" class="error-feedback" />
                </div>
              </div>
              <div class="row mb-3">
                <label for="addressTwo" class="col-sm-2 col-form-label"
                  >{{ $t("claims.new.address_two") }}
                  <span class="text-danger">*</span></label
                >
                <div class="col-sm-10">
                  <Field
                    type="text"
                    name="addressTwo"
                    class="form-control"
                    id="addressTwo"
                  />
                  <ErrorMessage name="addressTwo" class="error-feedback" />
                </div>
              </div>
              <div class="row mb-3">
                <label for="addressThree" class="col-sm-2 col-form-label"
                  >{{ $t("claims.new.address_three") }}
                  <span class="text-danger">*</span></label
                >
                <div class="col-sm-10">
                  <Field
                    type="text"
                    name="addressThree"
                    class="form-control"
                    id="addressThree"
                  />
                  <ErrorMessage name="addressThree" class="error-feedback" />
                </div>
              </div>
              <div class="row mb-3">
                <label for="addressFour" class="col-sm-2 col-form-label">{{
                  $t("claims.new.address_four")
                }}</label>
                <div class="col-sm-10">
                  <Field
                    type="text"
                    name="addressFour"
                    class="form-control"
                    id="addressFour"
                  />
                  <ErrorMessage name="addressFour" class="error-feedback" />
                </div>
              </div>
              <hr />
              <div
                class="alert alert-custom alert-indicator-left indicator-warning"
                role="alert"
                id="warningcard"
              >
                <div class="alert-content">
                  <span class="alert-title">{{
                    $t("claims.new.important_notice")
                  }}</span>
                  <span class="alert-text">{{
                    $t("claims.new.upload_as_much_evidence")
                  }}</span>
                </div>
              </div>
              <p v-html="$t('claims.new.columns_marked')"></p>
              <p>{{ $t("claims.new.evidence_minimum") }}</p>

              <table class="table" id="createnmop">
                <thead>
                  <tr>
                    <th scope="col">
                      {{ $t("claims.new.file") }}
                      <span class="text-danger">*</span>
                    </th>
                    <th scope="col">
                      {{ $t("claims.new.evidence_type") }}
                      <span class="text-danger">*</span>
                    </th>
                    <th scope="col">
                      {{ $t("claims.new.comments_on_evidence") }}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="file in claim.evidence" :key="file.id">
                    <td>
                      <div class="custom-file">
                        <input
                          :id="file.id + 'file'"
                          accept="image/png,image/jpeg,application/pdf"
                          @change="(event) => selectEvidence(event, file)"
                          class="custom-file-input"
                          type="file"
                        />
                        <label class="custom-file-label">
                          {{ file_name }}
                        </label>
                      </div>
                      <div class="invalid-feedback show" v-if="file.error">
                        {{ file.errorMsg }}
                      </div>
                      <div
                        v-if="file.file !== null"
                        class="row"
                        style="flex-wrap: nowrap"
                      >
                        <div class="m-t-sm">
                          <div
                            v-if="file.isPdf"
                            class="flex space-between m-b-md"
                          >
                            <span v-if="showAllPages">
                              {{ `${pageCount} ${$t("sites.data.pages")}` }}
                            </span>
                            <span v-else>
                              <button
                                :disabled="page <= 1"
                                @click="page--"
                                class="btn b-primary text-primary m-r-xs"
                                style="border-radius: 50px; width: 15px"
                              >
                                <i class="material-icons"> arrow_back_ios </i>
                              </button>
                              {{ page }} / {{ pageCount }}
                              <button
                                :disabled="page >= pageCount"
                                @click="page++"
                                class="btn b-primary text-primary m-l-xs"
                                style="border-radius: 50px; width: 15px"
                              >
                                <i class="material-icons">
                                  arrow_forward_ios
                                </i>
                              </button>
                            </span>
                            <div style="float: right">
                              <label class="m-t-xxs">
                                <input type="checkbox" v-model="showAllPages" />
                                {{ $t("sites.data.show_all_pages") }}
                              </label>
                            </div>
                          </div>
                          <vue-pdf-embed
                            v-if="file.isPdf"
                            :source="file.src"
                            ref="pdfRef"
                            :page="page"
                            @rendered="handleDocumentRender"
                          />
                          <img v-else class="image-preview" :src="file.src" />
                        </div>
                        <div class="parent-div" style="width: 55%">
                          <button
                            class="center-div btn btn-danger"
                            @click="
                              () => {
                                file.file = null;
                              }
                            "
                          >
                            {{ $t("global.clear") }}
                          </button>
                        </div>
                      </div>
                    </td>
                    <th scope="row">
                      <select
                        :disabled="file.permanent === true"
                        v-model="file.type"
                        class="form-select"
                        required
                      >
                        <option value="" selected>
                          {{ $t("claims.new.select_a_type") }}
                        </option>
                        <option value="vehicle_image">
                          {{ $t("claims.new.vehicleImage") }}
                        </option>
                        <option value="till_receipt">
                          {{ $t("claims.new.tillReceipt") }}
                        </option>
                        <option value="driveoff_report">
                          {{ $t("claims.new.drive_off_report") }}
                        </option>
                        <option value="additional_evidence">
                          {{ $t("claims.new.other") }}
                        </option>
                      </select>
                      <div :id="file.id + 'type'" class="invalid-feedback">
                        {{ $t("claims.new.valid_evidence_feedback") }}
                      </div>
                    </th>
                    <td>
                      <input
                        v-model="file.comments"
                        class="form-control"
                        type="text"
                      />
                    </td>
                    <td style="vertical-align: middle">
                      <i
                        v-if="file.permanent !== true"
                        class="material-icons v-icon"
                        @click="removeEvidence(file)"
                        >delete_outline</i
                      >
                    </td>
                  </tr>
                  <!-- <tr>
                    <td colspan="3">
                      <div id="evidenceFeedback" class="invalid-feedback">{{ $t('claims.new.evidence_minimum') }}</div>
                    </td>
                    <td><i class="material-icons text-success v-icon" @click="addEvidence">add</i></td>
                  </tr> -->
                  <tr class="new-evidence-btn" @click="addEvidence">
                    <td colspan="4">
                      <i
                        class="material-icons text-success p-h-xs"
                        style="vertical-align: middle"
                        >add_circle</i
                      >
                      <span class="text-success">
                        {{ $t("claims.new.add_new_evidence") }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <hr />
              <div
                class="alert alert-custom alert-indicator-left indicator-info"
                role="alert"
                id="commentcard"
              >
                <div class="alert-content">
                  <span class="alert-title">{{
                    $t("claims.new.comments")
                  }}</span>
                  <span class="alert-text"
                    >{{ $t("claims.new.please_provide_description") }}
                    <ul>
                      <li>{{ $t("claims.new.filling_cannister") }}</li>
                      <li>{{ $t("claims.new.attempt_to_pay") }}</li>
                    </ul>
                  </span>
                </div>
              </div>
              <label class="form-label"
                >{{ $t("claims.new.additional_info") }}
                <span class="text-danger"> * </span>
              </label>
              <textarea
                v-model="claim.comments"
                class="form-control"
                rows="4"
                :placeholder="$t('claims.new.please_provide_information')"
              ></textarea>

              <br />

              <div class="row mg-t-20">
                <div class="col-12">
                  <h5>{{ $t("claims.new.nmop.agreement.title") }}</h5>
                  <!-- Message card -->
                  <MessageCard
                    alertType="indicator-warning"
                    :alertTitle="$t('global.warning')"
                    :alertText="$t('claims.new.nmop.agreement.warning')"
                    id="warningcard"
                  />
                  <p v-html="$t('claims.new.nmop.agreement.line_one')"></p>
                  <p>{{ $t("claims.new.nmop.agreement.line_two") }}</p>
                  <p>{{ $t("claims.new.nmop.agreement.line_three") }}</p>
                  <p v-html="$t('claims.new.nmop.agreement.line_four')"></p>
                  <p class="tx-bold">
                    {{ $t("claims.new.nmop.agreement.line_five") }}
                  </p>
                  <p class="tx-bold tx-danger">
                    {{ $t("claims.new.nmop.agreement.line_six") }}
                  </p>

                  <label class="ckbox">
                    <input v-model="claim.accept_agreement" type="checkbox" />
                    <span class="m-l-xs">
                      {{ $t("claims.new.nmop.agreement.line_seven") }}
                    </span>
                  </label>
                </div>
              </div>

              <button
                type="submit"
                :disabled="is_future"
                class="btn btn-primary m-t-sm"
              >
                <span
                  v-show="submitting"
                  class="spinner-border spinner-border-sm m-r-xxs"
                ></span>
                <span>{{
                  $t("claims.new.create_button", { text: "Claim" })
                }}</span>
              </button>
              <div v-if="hasErrors" class="invalid-feedback show">
                {{ $t("claims.new.error_with_data") }}
              </div>
              <div v-if="is_future" class="invalid-feedback show">
                <p>The incident date/time must not be in the future!</p>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="submitClaimModal"
      tabindex="-1"
      aria-labelledby="submitClaimTitle"
      style="display: none"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="submitClaimTitle">
              {{ $t("claims.new.submitting_claim") }}
            </h5>
          </div>
          <div class="modal-body">
            <p class="text-align: center;">{{ submission.message }}</p>
            <div class="progress">
              <div
                class="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                :aria-valuenow="submission.completed"
                aria-valuemin="0"
                :aria-valuemax="submission.steps"
                :style="`width: ${Math.floor(
                  (submission.completed / submission.steps) * 100
                )}%`"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="successModal"
      tabindex="-1"
      aria-labelledby="successModalTitle"
      style="display: none"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="successModalTitle">
              {{ $t("claims.new.success").toUpperCase() }}
              <i class="material-icons text-success successModal_icon">
                check_circle
              </i>
            </h5>
          </div>
          <div class="modal-body" style="padding-top: 10px">
            <p>
              {{ $t("claims.new.claim_created") }}
              <strong id="nmop_reference">{{ submission.reference }}</strong>
              <i
                class="material-icons successModal_icon cursor-pointer"
                @click="copyToClipboard"
              >
                file_copy
              </i>
            </p>
            <div class="flex-center">
              <button
                class="btn btn-secondary m-r-sm"
                @click.prevent="openEvidence"
              >
                {{ $t("navigation.side.manage_claims") }}
              </button>
              <button class="btn btn-primary" @click.prevent="openDashboard">
                {{ $t("claims.new.go_to_dashboard") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="abnormalValueModal"
      tabindex="-1"
      aria-labelledby="abnormalValueModalTitle"
      style="display: none"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="abnormalValueModalTitle">
              Warning
              <i class="material-icons text-warning successModal_icon">
                warning
              </i>
            </h5>
          </div>
          <div class="modal-body" style="padding-top: 10px">
            <p>
              The values you have entered appear to be larger than we would
              expect, please double check them below and confirm if they are
              correct
            </p>
            <table class="table">
              <thead>
                <tr>
                  <th>Type of Fuel</th>
                  <th>Litres</th>
                  <th>Value</th>
                  <th>Pump</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="fuel in claim.fuels">
                  <td>{{ fuel.type }}</td>
                  <td>{{ fuel.litres }}</td>
                  <td>{{ fuel.value }}</td>
                  <th>{{ fuel.pump }}</th>
                </tr>
              </tbody>
            </table>
            <div class="flex-center">
              <button
                class="btn btn-secondary m-r-sm"
                @click.prevent="abnormalModal.hide()"
              >
                Make Changes
              </button>
              <button
                class="btn btn-success"
                @click.prevent="acceptAbnormalValues"
              >
                The values are correct
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import ClientService from "../../../services/client.service";
import ExternalService from "../../../services/external.service";
import { v4 as uuidv4 } from "uuid";
import ClaimsService from "../../../services/claims.service";
import { DateTime } from "luxon";
import MessageCard from "../../../components/MessageCard.vue";
import VuePdfEmbed from "vue-pdf-embed";
import axios from "axios";
import authHeader from "../../../services/auth-header";
import Swal from "sweetalert2";
import { Multiselect } from "vue-multiselect";

export default {
  name: "CreateNmop",
  components: {
    Form,
    Field,
    ErrorMessage,
    MessageCard,
    VuePdfEmbed,
    Multiselect,
  },
  data() {
    const schema = yup.object().shape({
      registration: yup
        .string()
        .required()
        .min(3, this.$t("claims.new.min_3_chars"))
        .max(12, this.$t("claims.new.max_12_chars")),
      model: yup.string().required(),
      make: yup.string().required(),
      colour: yup.string().required(),
      date: yup.date().required(),
      time: yup
        .string()
        .required()
        .matches(
          "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$",
          $("claims.new.must_be_valid_time") + "HH:mm"
        ),
    });
    return {
      schema: schema,
      claim: {
        site: null,
        postcode: null,
        comments: null,
        fuels: [
          {
            id: Math.floor(Math.random() * 10 + 1),
            type: "",
            value: null,
            litres: null,
            pump: null,
          },
        ],
        evidence: [
          {
            id: uuidv4(),
            file: null,
            type: "vehicle_image",
            comments: null,
            error: false,
            errorMsg: "",
            permanent: true,
            src: null,
            isPdf: false,
          },
          {
            id: uuidv4(),
            file: null,
            type: "till_receipt",
            comments: null,
            error: false,
            errorMsg: "",
            permanent: true,
            src: null,
            isPdf: false,
          },
        ],
        accept_agreement: false,
        date: null,
        time: null,
      },
      sites: [],
      addresses: [],
      findingAddresses: false,
      hasErrors: false,
      submitting: false,
      submission: {
        steps: 0,
        completed: 0,
        claim_id: null,
        reference: null,
        message: "",
      },
      modal: null,
      successModal: null,
      page: 1,
      pageCount: 1,
      showAllPages: false,
      showCnaf: false,
      accept_abnormal_values: false,
      abnormalModal: null,
    };
  },
  mounted() {
    this.loadSites();
    this.modal = new bootstrap.Modal(
      document.getElementById("submitClaimModal")
    );
    this.successModal = new bootstrap.Modal(
      document.getElementById("successModal")
    );
    this.abnormalModal = new bootstrap.Modal(
      document.getElementById("abnormalValueModal")
    );
    this.checkCnaf();
  },
  methods: {
    checkEmailValidity() {
      let ele = document.getElementById("customerEmail");
      ele.reportValidity();
    },
    checkCnaf() {
      axios
        .get(
          `https://api.varsanpr.com/api/clients/${this.$store.state.auth.user.selectedClient}/cnaf`,
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          if (response.data.cnaf == true) {
            this.showCnaf = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadSites() {
      ClientService.getClientSites(
        this.$store.state.auth.user.selectedClient,
        false,
        null,
        null,
        ["sites.tier"]
      ).then((response) => {
        this.sites = response.data.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        if(this.sites.length === 1){
          this.claim.site = this.sites[0];
        }
      });
    },
    addFuel() {
      if (this.claim.fuels.length > 1) return;
      let id = Math.floor(Math.random() * 10 + 1);
      if (
        this.claim.fuels.filter((f) => {
          return f.id === id;
        }).length > 0
      ) {
        id = Math.floor(Math.random() * 10 + 1);
      }
      this.claim.fuels.push({
        id: id,
        type: "",
        value: null,
        litres: null,
        pump: null,
      });
    },
    deleteFuel(fuel) {
      if (this.claim.fuels.length === 1) return;
      this.claim.fuels = this.claim.fuels.filter((elem) => {
        return JSON.stringify(elem) !== JSON.stringify(fuel);
      });
    },
    acceptAbnormalValues() {
      this.accept_abnormal_values = true;
      this.abnormalModal.hide();
      this.$refs.createClaimButton.click();
    },
    createClaim(values, reset) {
      if (!this.claim.accept_agreement) return;
      this.submitting = true;
      this.hasErrors = false;
      let feedbackNotices = document.getElementsByClassName("invalid-feedback");
      for (let element of feedbackNotices) {
        element.classList.remove("show");
      }

      console.log("Removed Show");

      // Check for weird values
      if (!this.accept_abnormal_values) {
        let foundAbnormal = false;
        this.claim.fuels.forEach((fuel) => {
          if (fuel.value > 150 || fuel.litres > 100) {
            foundAbnormal = true;
          }
        });
        if (foundAbnormal) {
          this.abnormalModal.show();
          this.submitting = false;
          return;
        }
      }

      this.accept_abnormal_values = false;

      // Vee has validated most of the inputs however the more comlex inputs will need to be
      // manually validated by us.

      let foundFuelError = false;
      if (this.claim.fuels.length < 1) {
        // we must have at least 1 type of fuel
        document.getElementById("fuelTypeFeedback").classList.add("show");
        foundFuelError = true;
      }

      this.claim.fuels.forEach((fuel) => {
        if (fuel.type === null || fuel.type === "") {
          document.getElementById(`${fuel.id}type`).classList.add("show");
          foundFuelError = true;
        }
        if (fuel.value === null) {
          document.getElementById(`${fuel.id}value`).classList.add("show");
          foundFuelError = true;
        }
        if (fuel.litres === null) {
          document.getElementById(`${fuel.id}litres`).classList.add("show");
          foundFuelError = true;
        }
        if (fuel.pump === null) {
          document.getElementById(`${fuel.id}pump`).classList.add("show");
          foundFuelError = true;
        }
      });

      // As a minimum we require 1 vehicle image and 1 till receipt image
      let foundEvidenceError = false;
      if (this.claim.evidence.length < 1) {
        document.getElementById("evidenceFeedback").classList.add("show");
        foundEvidenceError = true;
      }

      let foundVehicle = false;
      let foundTill = false;
      this.claim.evidence.forEach((e) => {
        if (e.file === null || e.file === "") {
          e.errorMsg = "Please select a file or remove";
          e.error = true;
          foundEvidenceError = true;
        }
        if (e.type === null || e.type === "") {
          document.getElementById(`${e.id}type`).classList.add("show");
          foundEvidenceError = true;
        }
        if (e.type === "vehicle_image") {
          foundVehicle = true;
        }
        if (e.type === "till_receipt") {
          foundTill = true;
        }
      });

      if (!foundVehicle || !foundTill) {
        document.getElementById("evidenceFeedback").classList.add("show");
      }

      if (!foundVehicle || !foundTill || foundEvidenceError || foundFuelError) {
        this.hasErrors = true;
        this.submitting = false;
        return;
      }

      this.modal.show();

      // Handle Uploading
      // First we need to create the raw claim with the data provided
      // then we need to upload the evidence to the claim once we have the claim ID
      // During this process we need to show the progress being made to the user so they
      // don't click off the modal

      console.log(`${values.date} ${values.time}`);
      let timestamp = DateTime.fromFormat(
        `${values.date} ${values.time}`,
        "yyyy-MM-dd HH:mm"
      ).toSeconds();

      this.submission.steps = 1 + this.claim.evidence.length;
      this.submission.completed = 0;
      this.submission.message = this.$t("claims.new.creating_claim");

      let data = new FormData();
      data.append("site_id", this.claim.site.id);
      data.append("timestamp", timestamp);
      data.append(
        "registration",
        values.registration.replace(/\s/g, "").toUpperCase()
      );
      data.append("fuels", JSON.stringify(this.claim.fuels));
      data.append("comments", this.claim.comments);
      data.append("name", values.customer);
      if (
        values.email !== undefined &&
        values.email !== null &&
        values.email !== "" &&
        values.email !== "undefined"
      ) {
        data.append("email", values.email);
      }
      data.append("telephone", values.mobile);
      data.append("postcode", values.postcode);
      data.append("address_one", values.addressOne);
      data.append("address_two", values.addressTwo);
      data.append("address_three", values.addressThree);
      data.append("address_four", values.addressFour);
      data.append("accept_agreement", true);

      ClaimsService.createNmop(data)
        .then((response) => {
          // "success": true,
          // "reference": "AD10100007106",
          // "id": 2106
          this.submission.completed += 1;
          this.submission.claim_id = response.data.id;
          this.submission.reference = response.data.reference;
          this.uploadEvidence(reset);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async uploadEvidence(reset) {
      if (
        this.submission.claim_id === null ||
        this.submission.claim_id === undefined
      )
        return;
      for await (let file of this.claim.evidence) {
        this.submission.message = `${this.$t("claims.new.uploading")} ${
          this.submission.completed
        } of ${this.claim.evidence.length} ${this.$t(
          "claims.new.evidence_files"
        )}`;
        try {
          let response = await ClaimsService.uploadEvidenceToClaim(
            this.$store.state.auth.user.selectedClient,
            this.submission.claim_id,
            file.file,
            file.type,
            file.comments
          );
          this.submission.completed++;
        } catch (error) {
          console.error(error);
          this.submission.completed++;
        }
      }

      this.modal.hide();
      this.successModal.show();

      reset();
      this.claim.evidence.forEach((file) => {
        document.getElementById(`${file.id}file`).value = "";
      });
      this.claim.evidence = [
        {
          id: uuidv4(),
          file: null,
          type: "vehicle_image",
          comments: null,
          error: false,
          errorMsg: "",
          permanent: true,
          src: null,
          isPdf: false,
        },
        {
          id: uuidv4(),
          file: null,
          type: "till_receipt",
          comments: null,
          error: false,
          errorMsg: "",
          permanent: true,
          src: null,
          isPdf: false,
        },
      ];

      this.claim.fuels = [
        {
          id: Math.floor(Math.random() * 10 + 1),
          type: "",
          value: null,
          litres: null,
          pump: null,
        },
      ];

      this.claim.site = null;
      this.submitting = false;
      // this.successModal.hide();
    },
    getMMC(v) {
      console.log(v.target.value);
      ExternalService.getMMC(v.target.value.replace(/\s/g, "").toUpperCase())
        .then((response) => {
          this.$refs.claimForm.setFieldValue(
            "make",
            response.data.vehicle.make
          );
          this.$refs.claimForm.setFieldValue(
            "model",
            response.data.vehicle.model
          );
          this.$refs.claimForm.setFieldValue(
            "colour",
            response.data.vehicle.colour
          );
        })
        .catch((error) => {
          console.error(error);
        });
    },
    vehicleCheck(v) {
      let reg = v.target.value.replace(/\s/g, "").toUpperCase();
      axios
        .get(
          `https://api.varsanpr.com/api/vehicle/${reg}/history?site_id=${this.claim.site.id}`,
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          let claims = response.data.data.claims;
          let alert = false;
          claims.forEach((claim) => {
            if (
              ![
                "Paid",
                "Paid on Site",
                "Cancelled",
                "AUTOCANCELLATION",
              ].includes(claim.status)
            ) {
              alert = true;
            }
          });

          if (alert) {
            Swal.fire({
              icon: "warning",
              title: `We have found ${response.data.data.claims.length} outstanding claims for this vehicle`,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getAddress() {
      this.findingAddresses = true;
      ExternalService.getAddressByPostcode(this.claim.postcode)
        .then((response) => {
          this.addresses = response.data.addresses || [];
          console.log(document.getElementById("addressFilter"));
          document.getElementById("addressFilter").focus();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.findingAddresses = false;
        });
    },
    selectAddress(v) {
      let currentAddress = v.target.value.replace(/ ,/g, "").split(",");
      this.$refs.claimForm.setFieldValue(
        "addressOne",
        (currentAddress[0] || "").trim()
      );
      this.$refs.claimForm.setFieldValue(
        "addressTwo",
        (currentAddress[1] || "").trim()
      );
      this.$refs.claimForm.setFieldValue(
        "addressThree",
        (currentAddress[2] || "").trim()
      );
      this.$refs.claimForm.setFieldValue(
        "addressFour",
        (currentAddress[3] || "").trim()
      );
    },
    selectEvidence(event, file) {
      if (event.target.files[0].size > 26214400) {
        file.errorMsg = this.$t("claims.new.file_size_error");
        file.error = true;
        event.target.value = "";
        event.target.classList.add("shake");
        setTimeout(() => {
          event.target.classList.remove("shake");
        }, 500);
        return;
      }
      let type = event.target.files[0].name.split(".").pop().toLowerCase();
      let invalidCount = 0;
      ["png", "jpg", "pdf", "jpeg"].forEach((t) => {
        if (type.indexOf(t) === -1) {
          invalidCount++;
        }
      });
      if (invalidCount >= 4) {
        file.errorMsg = this.$t("claims.new.file_format_error");
        file.error = true;
        event.target.value = "";
        event.target.classList.add("shake");
        setTimeout(() => {
          event.target.classList.remove("shake");
        }, 500);
        return;
      }
      file.file = event.target.files[0];

      let reader = new FileReader();
      reader.onload = (event) => {
        file.src = event.target.result;
      };

      if (type === "pdf") {
        file.isPdf = true;
        reader.readAsArrayBuffer(file.file);
        return;
      }
      reader.readAsDataURL(file.file);
    },
    addEvidence() {
      this.claim.evidence.push({
        id: uuidv4(),
        file: null,
        type: "",
        comments: null,
        error: false,
        errorMsg: "",
      });
    },
    removeEvidence(file) {
      this.claim.evidence = this.claim.evidence.filter((e) => {
        return e.id !== file.id;
      });
    },
    handleDocumentRender() {
      this.pageCount = this.$refs.pdfRef.pageCount;
    },
    copyToClipboard() {
      let reference = document.getElementById("nmop_reference").textContent;
      navigator.clipboard
        .writeText(reference)
        .then(() => {
          this.$success("Copied reference number: " + reference);
        })
        .catch((error) => {
          this.$error("Failed to copy reference number", error);
        });
    },
    openEvidence() {
      this.successModal.hide();
      this.$router.push("/claims/evidence");
    },
    openDashboard() {
      this.successModal.hide();
      this.$router.push("/");
    },
  },
  computed: {
    file_name: function () {
      for (let elem of this.claim.evidence) {
        if (elem.file == null) return "Select File";
        if (elem.file.name == null) return "File Selected";
        return elem.file.name;
      }
    },
    is_future: function () {
      let date = this.claim.date;
      let time = this.claim.time;

      if (date === null) return false;

      if (time === null) {
        time = "00:00";
      }

      let timestamp = DateTime.fromFormat(
        `${date} ${time}`,
        "yyyy-MM-dd HH:mm"
      ).toSeconds();
      let now = DateTime.local().toSeconds();

      return timestamp > now;
    },
    lockForm: function () {
      if (this.claim.site?.tier === "Discontinued") {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.v-icon {
  cursor: pointer;
  line-height: 1.6em;
  opacity: 0.7;
}
.v-icon:hover {
  opacity: 1;
}

.show {
  display: block !important;
}

.invalid-feedback {
  color: #ff4857 !important;
}

@keyframes shake {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
  }
}

.shake {
  -webkit-animation-name: shake;
  -webkit-animation-duration: 0.5s;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-iteration-count: infinite;
}

.nav-tabs {
  border: none;
}

.disabled {
  opacity: 0.5;
}

.disabled:hover {
  border-bottom: none;
}

.addFuel-table {
  border: none;
}

.addFuel-tr {
  border-color: #2d2d3f;
}

.image-preview {
  position: relative;
  margin-top: 10px;
  width: 100%;
}

/* .fuel-input-card {
  background-color: var(--bg-color-tertiary);
  box-shadow: none;
} */

.new-fuel-btn {
  width: fit-content;
  padding: 10px;
  margin: 10px auto;
}

.new-evidence-btn {
  width: fit-content;
  padding: 10px;
}

#second-fuel:hover,
.new-evidence-btn td:hover {
  background-color: rgba(75, 173, 72, 0.25);
  border-radius: 10px;
  cursor: pointer;
}

.grid {
  display: grid;
  grid-template-columns: 24% 23% 23% 23%;
  gap: 1.5em;
}

/* .card.fuel-input-card thead, tr{
  border-bottom: 3px solid #1F1F2B;
} */

.new-evidence-btn td {
  text-align: center;
}

.material-icons.v-icon:hover {
  cursor: pointer;
  color: #ff4857;
}

.successModal_icon {
  vertical-align: middle;
  margin: 0 5px;
}

#addressFilter:focus {
  border: 1px solid var(--utility-blue);
}

/* Removes default choose input button for input type file */
::-webkit-file-upload-button {
  display: none;
}

@media (min-width: 1920px) {
  .grid {
    grid-template-columns: 24% 24% 24% 24%;
  }
}
</style>
